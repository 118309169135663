<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">{{ $store.getters['languageMixin/getStringFromLanguage'](lang.label) }} {{ $store.getters['languageMixin/getStringFromLanguage']('Section') }}</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <ValidationProvider
            :vid="lang.value + '.page_name'"
            rules=""
            name="The Page Name"
            v-slot="{ passed, failed,errors }">
            <fg-input type="text"
                      :label="$store.getters['languageMixin/getStringFromLanguage']('Page Name')"
                      :error="failed ? errors[0]: null"
                      name="page_name"
                      fou
                      v-model="formData.page_name">
            </fg-input>
          </ValidationProvider>
          <ValidationProvider
            :vid="lang.value + '.page_heading'"
            rules=""
            name="The Page Heading"
            v-slot="{ passed, failed,errors }">
            <fg-input type="text"
                      :label="$store.getters['languageMixin/getStringFromLanguage']('Page Heading')"
                      :error="failed ? errors[0]: null"
                      name="page_heading"
                      fou
                      v-model="formData.page_heading">
            </fg-input>
          </ValidationProvider>
          <div class="form-group">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Content') }}</label>
            <editor
              v-model="formData.page_content"
              :api-key="editorKey"
              :init='editorConfig'
            />
          </div>
          <fg-input type="text"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Meta Title')"
                    name="meta_title"
                    v-model="formData.meta_title">
          </fg-input>
          <div class="row">
            <div class="col-md-6">
              <fg-text type="text"
                       :label="$store.getters['languageMixin/getStringFromLanguage']('Meta Description')"
                       name="meta_description"
                       v-model="formData.meta_description">
              </fg-text>
            </div>
            <div class="col-md-6">
              <fg-text type="text"
                       :label="$store.getters['languageMixin/getStringFromLanguage']('Meta Keywords')"
                       name="meta_keywords"
                       v-model="formData.meta_keywords">
              </fg-text>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex } from "vee-validate/dist/rules";
  import Editor from '@tinymce/tinymce-vue'
  import FormGroupTextArea from "@/components";

  extend("required", required);
  extend("email", email);
  extend("regex", regex);

  export default {
    components: {
      FormGroupTextArea,
      [Select.name]: Select,
      [Option.name]: Option,
      'editor': Editor
    },
    props: {
      lang: {
        type: Object
      },
      editMode: {
        type: Boolean,
        default: false
      },
      isSubmit: {
        type: Boolean,
        default: false
      },
      formData:{
        type: Object,
        default() {
          return {
            page_heading: "",
            page_name:"",
            content: "",
            meta_title: "",
            meta_keywords: "",
            meta_description: "",
          }
        }
      },
    },
    data () {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang.value, this.isSubmit)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
    }
  }
</script>
<style>
</style>
